import React from 'react';
import ContentPage from '../component/ContentPage';
import { Col, Row } from 'react-bootstrap';
import '../css/Social.scss';
import instagram1 from '../img/instagram-1.jpeg';
import instagram2 from '../img/instagram-2.jpeg';
import instagram3 from '../img/instagram-3.jpeg';
import instagram4 from '../img/instagram-4.jpeg';
import instagram5 from '../img/instagram-5.jpeg';

const IMAGES = [
    {
        href: 'https://www.instagram.com/p/CULyOvUJPBw/',
        src: instagram1,
        handle: '@lungsintheair',
        caption:
            'Hey #yyc! This #WorldLungDay, learn how the health of your lungs is up in the air.',
    },
    {
        href: 'https://www.instagram.com/p/CULy9oupRVc/',
        src: instagram2,
        handle: '@lungsintheair',
        caption:
            'Hey @environmentca and @GovCanHealth! The health of my lungs is up in the air.',
    },
    {
        href: 'https://www.instagram.com/p/CULz0B8JXH4/',
        src: instagram3,
        handle: '@lungsintheair',
        caption:
            'To help spread the importance of lung health, we created the #LungsInTheAir, a massive lung sculpture that will travel across Canada.',
    },
    {
        href: 'https://www.instagram.com/p/CUL3NBmp8N1/',
        src: instagram4,
        handle: '@lungsintheair',
        caption:
            'Let’s not take our air for granted,#yyc. More than 15k Canadians die each year due to poor air quality.',
    },
    {
        href: 'https://www.instagram.com/p/CUL3AuipERF/',
        src: instagram5,
        handle: '@lungsintheair',
        caption:
            'We need to make cleaner air a priority to help protect Canadian lungs.',
    },
];

export default function Social() {
    return (
        <ContentPage title="Social" id="Social">
            <Row className="mb-4">
                <h5>Follow the</h5>
                <p>
                    <a href="https://www.instagram.com/lungsintheair/" target="_blank" rel="noreferrer">
                        #LungsInTheAir
                    </a>
                </p>
            </Row>
            <Row className="SocialImages">
                {IMAGES.map((image) => {
                    return (
                        <Col key={image.src} className="md">
                            <InstagramImage {...image} />
                        </Col>
                    );
                })}
            </Row>
        </ContentPage>
    );
}

function InstagramImage({ src, handle, caption, href }) {
    return (
        <div>
            <div className="mb-4 SocialImageContainer">
                <a href={href} target="_blank" rel="noreferrer">
                    <img className="SocialImage" src={src} alt="Instagram Post" />
                </a>
                <div className="SocialHandle">{handle}</div>
            </div>
            <div className="SocialCaption">{caption}</div>
        </div>
    );
}
