import React, { Component } from 'react';

import ParticleTable from './ParticleTable';
import { Tween } from 'react-gsap';

class City extends Component{

    constructor(props){
        super(props);
        this.tween = React.createRef();
    }

    componentDidMount(){
        
    }

    componentDidUpdate(prevProps){
        // console.log('componentDidUpdate',prevProps,this.props);
        if (prevProps.cityid!==this.props.cityid){
            this.tween.current.getGSAP().play(0);
        }
    }

    render(){
        const { cityid, aqhi_id } = this.props;
        const cityname = cityid.split(',')[0];
        // const citydata = data[cityname.toLowerCase()];

        // console.log(this.props.cityid, data, citydata);
        return(
            <Tween from={{
                x:200,
                opacity:0
            }} ref={this.tween}>
                <div className="city-wrapper">
                    {/* account for stupid Vancouver BC data naming convention in IQAir dataset */}
                    <h2 className="mb-3">{ cityname==="Vancouver BC"?cityname.split(" ")[0]:cityname }</h2>
                    {/* <p className="mb-4">{ citydata.description }</p> */}
                    <ParticleTable cityid={cityid} aqhi_id={aqhi_id} />
                </div>
            </Tween>
        );
    }
}

export default City;