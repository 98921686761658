const glsl = require('glslify');

export const fragShader = glsl`precision highp float;

uniform sampler2D uTexture;
uniform sampler2D uGroupTex;

uniform float uParticles[5];

varying vec2 vPUv;
varying vec2 vUv;

void main() {
    vec4 color = vec4(0.0);
    vec2 uv = vUv;
    vec2 puv = vPUv;

    vec4 colA = texture2D(uTexture, puv);
    
    float grey = colA.r * 0.21 + colA.g * 0.71 + colA.b * 0.07;
    vec4 colB = vec4(grey, grey, grey, 1.0);

    float border = 0.3;
    float radius = 0.5;
    float dist = radius - distance(uv, vec2(0.5));
    float t = smoothstep(0.0, border, dist);

    vec4 colG = texture2D(uGroupTex, puv);
    int cMap = int(colG.r) + int(colG.g)*3 + int(colG.b)*5;
    float cVal = 1.0;
    if (cMap==4)
        cVal = uParticles[0];
    else if (cMap==3)
        cVal = uParticles[1];
    else if (cMap==5)
        cVal = uParticles[2];
    else if (cMap==6)
        cVal = uParticles[3];
    else if (cMap==8)
        cVal = uParticles[4];

    if (cVal<.3) 
        color = colB;
    else {
        color = colA; // colB
    }
    
    // if (colG.r>colG.b && colG.g>colG.b) 
    //     color = colA * uParticles[0];
    // else if (colG.g>colG.r && colG.g>colG.b)
    //     color = colA * uParticles[1];
    // else if (colG.b>colG.r && colG.b>colG.g)
    //     color = colA * uParticles[2];
    // else if (colG.r>colG.g && colG.b>colG.g)
    //     color = colA * uParticles[3];
    // else if (colG.g>colG.r && colG.b>colG.r)
    //     color = colA * uParticles[4];
    // else 
        
    
    color.a = t;

    gl_FragColor = color;
}`;