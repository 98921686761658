import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import eventBus from "../component/utils/EventBus";
import { Tween } from 'react-gsap';

import '../css/See.scss';

const citiesList = [
    { name: "Calgary", value: "Calgary,Alberta" },
    { name: "Charlottetown", value: "Charlottetown,Prince Edward Island" },
    { name: "Halifax", value: "Halifax,Nova Scotia" },
    // { name: "Iqaluit", value: "Iqaluit," },
    { name: "Moncton", value: "Moncton,New Brunswick" },
    { name: "Montreal", value: "Montreal,Quebec" },
    { name: "Ottawa", value: "Ottawa,Ontario" },
    { name: "Saskatoon", value: "Saskatoon,Saskatchewan" },
    { name: "St. John's", value: "St. John's,Newfoundland and Labrador" },
    { name: "Toronto", value: "Toronto,Ontario" },
    { name: "Vancouver", value: "Vancouver BC,British Columbia" },
    // { name: "Whitehorse", value: "Whitehorse," },
    { name: "Winnipeg", value: "Winnipeg,Manitoba" },
    { name: "Yellowknife", value: "Yellowknife,Northwest Territories" },
]

class See extends Component{

    componentDidMount(){
        
    }

    dispatchBG(){
        eventBus.dispatch("updateBackground", { message: "see" });
    }

    handleCityChange(e){
        if (e.target.value==='') return;
        eventBus.dispatch("updateCity", {message:e.target.value});
        document.location.hash = "#Data";
    }

    render(){
        return(
            <Container className="fullpage" id="See">
                <Row className="h-100 align-items-center">
                    <Tween from={{
                            // x: '-200px',
                            opacity: 0,
                            scrollTrigger: {
                                trigger: '#See',
                                // scrub: true,
                                // start: 'top top',
                                end: '25% top',
                                toggleActions: 'play reverse play reverse'
                            }
                        }} onComplete={this.dispatchBG}>
                        <Col sm={{ span:10, offset:1 }} lg={{ span:8, offset:2 }} xl={{ span:6, offset:3 }} className="with-backdrop">
                            <h3 className="mb-5">See what<br/>you're breathing</h3>
                            <p className="mb-5">To protect yourself from air pollution we created virtual lungs
that reflect your location&#39;s Air Quality Health Index (AQHI): a scale calculated based on the major air pollutants found to be the best indicator of health risk – even at low levels of
exposure. Help spread the importance of lung health and make
air quality a priority for Environment Canada.</p>
                            
                                <select onChange={this.handleCityChange}>
                                    <option value="">See what we breathe across Canada</option>
                                    {citiesList.map(({name,value},index)=>(
                                        <option value={value} key={index+1}>{name}</option>
                                    ))}
                                </select>
                        </Col>
                    </Tween>
                </Row>
            </Container>
          );
    }
}

export default See;