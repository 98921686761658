import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import eventBus from "../component/utils/EventBus";
import { Tween } from 'react-gsap';

import logo from '../img/breathe-logo.png';

import '../css/Home.scss';

class Home extends Component{

    componentDidMount(){
        
    }

    dispatchBG(){
        eventBus.dispatch("updateBackground", { message: "home" });
    }
    dispatchAudio(){
        eventBus.dispatch("toggleAudio", {});
        document.location.hash = "#See";
    }

    render(){
        return(
            <Container className="fullpage" id="Home">
                <Row className="h-100 align-items-center">
                    <Tween from={{
                            // x: '-200px',
                            opacity: 0,
                            scrollTrigger: {
                                trigger: '#Home',
                                // scrub: true,
                                // start: 'top top',
                                end: '25% top',
                                toggleActions: 'play reverse play reverse'
                            }
                        }} onComplete={this.dispatchBG}>
                        <Col sm={{ span:10, offset:1 }} lg={{ span:8, offset:2 }} xl={{ span:6, offset:3 }}>
                            <img src={logo} className="breathe-logo mb-5" alt="Breathe: The Lung Association" />
                            {/* <Tween to={{
                                fontWeight:900,
                                yoyo:true,
                                repeat:-1
                            }}> */}
                                <h1>LUNGS</h1>
                            {/* </Tween> */}
                            <h2 className="mb-5">In the air</h2>
                            <p className="mb-5">More than 15,000
Canadians die each year as a result of poor air quality. It’s time for us to protect our
lungs and be aware of the air we breathe.</p>
                            <button onClick={this.dispatchAudio} className="audio-btn">Breathe along with this experience</button>
                        </Col>
                    </Tween>
                </Row>
            </Container>
          );
    }
}

export default Home;