import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { City } from '../component';

import eventBus from "../component/utils/EventBus";
import { Tween } from 'react-gsap';

import '../css/DataViz.scss';

import {
    FiFacebook,
    FiInstagram,
    FiTwitter,
} from 'react-icons/fi';

const citiesList = [
    { name: "Calgary", value: "Calgary,Alberta", aqhi_id: "calgary" },
    { name: "Charlottetown", value: "Charlottetown,Prince Edward Island", aqhi_id: "charlottetown" },
    { name: "Halifax", value: "Halifax,Nova Scotia", aqhi_id: "halifax" },
    // { name: "Iqaluit", value: "Iqaluit," },
    { name: "Moncton", value: "Moncton,New Brunswick", aqhi_id: "moncton" },
    { name: "Montreal", value: "Montreal,Quebec", aqhi_id: "montreal" },
    { name: "Ottawa", value: "Ottawa,Ontario", aqhi_id: "ottawa" },
    { name: "Saskatoon", value: "Saskatoon,Saskatchewan", aqhi_id: "saskatoon" },
    { name: "St. John's", value: "St. John's,Newfoundland and Labrador", aqhi_id: "stjohns" },
    { name: "Toronto", value: "Toronto,Ontario", aqhi_id: "toronto" },
    { name: "Vancouver", value: "Vancouver BC,British Columbia", aqhi_id: "vancouver" },
    // { name: "Whitehorse", value: "Whitehorse," },
    { name: "Winnipeg", value: "Winnipeg,Manitoba", aqhi_id: "winnipeg" },
    { name: "Yellowknife", value: "Yellowknife,Northwest Territories", aqhi_id: "yellowknife" },
]

class DataViz extends Component{

    constructor(props){
        super(props);
        this.state = {
            city:'', // default,
            aqhi_id:''
        }
        this.dispatchBG = this.dispatchBG.bind(this);
    }

    componentDidMount(){
        eventBus.on("updateCity", (data) => {
                const basecity = citiesList.find( ({value}) => value === data.message );
                this.setState({city:data.message,aqhi_id:basecity.aqhi_id})
            }
        );
    }

    dispatchBG(){
        eventBus.dispatch("updateBackground", { message: "lungs" });
        // set city if not already set.
        if (this.state.city===''){
            var today = new Date();
            var oct5 = new Date(2021,9,5);
            var defaultCity = today.valueOf()<oct5.valueOf() ? 2 : 8; // halifax or toronto?
            console.log(today,oct5,defaultCity);
            this.setState({city:citiesList[defaultCity].value,aqhi_id:citiesList[defaultCity].aqhi_id}); // Toronto default
        }
    }

    handleCityChange = e => {
        if (e.target.value==='') return;
        const basecity = citiesList.find( ({value}) => value === e.target.value );
        this.setState({city:e.target.value,aqhi_id:basecity.aqhi_id});
    }

    //facebook share new window
    fbShare = () => {
        const { city } = this.state;
        let cityname = city.split(",")[0];
        if (cityname==="Vancouver BC") cityname = "Vancouver";
        const cityurl = cityname==="St. John's" ? "StJohns" : cityname;
        const siteDomain = 'lungsintheair.ca'; 
        const shareLink = `https://www.facebook.com/sharer/sharer.php?u=https://${siteDomain}/?${cityurl}`;
        window.open(shareLink, "Lungs - Social Share", "width=500,height=500");
    }

    //twitter share new window // not used.
    twShare = () => {
        const { city } = this.state;
        let cityname = city.split(",")[0];
        if (cityname==="Vancouver BC") cityname = "Vancouver";
        const cityurl = cityname==="St. John's" ? "StJohns" : cityname;
        const siteDomain = 'lungsintheair.ca'; 
        // 🫁🇨🇦
        const twsharemsg = `See what I’m breathing in ${cityname}. I stand behind the action @environmentca and @GovCanHealth is taking to make air quality a priority for the sake of our lungs. 🫁 🇨🇦 @canlung #LungsInTheAir`;

        const shareLink = `https://twitter.com/intent/tweet?url=https%3A%2F%2F${siteDomain}%2F%3F${cityurl}&text=${encodeURIComponent(twsharemsg)}`;
        window.open(shareLink, "Lungs - Social Share", "width=500,height=500");
    }

    checkKeyPressFB = (e) => {
        console.log('keypress',e);
        if (e.code==='Enter' || e.code==='Space'){
            this.fbShare();
            return false;
        }
    }
    checkKeyPressTW = (e) => {
        console.log('keypress',e);
        if (e.code==='Enter' || e.code==='Space'){
            this.twShare();
            return false;
        }
    }

    render(){
        const { city, aqhi_id } = this.state;
        return(
            <Fragment>
                <Container className="fullpage" id="Data">
                    <Row className="h-100 align-items-center">
                    <Tween from={{
                                // x: '-200px',
                                opacity: 0,
                                scrollTrigger: {
                                    trigger: '#Data',
                                    // scrub: true,
                                    start: 'top center',
                                    end: 'center top',
                                    toggleActions: 'play reverse play reverse'
                                }
                            }} onComplete={this.dispatchBG}>
                            <Col sm={{ span:5, order:"last" }} className="text-start" >
                                <select className="mb-4" onChange={this.handleCityChange}>
                                    <option value="">EXPLORE OTHER LOCATIONS</option>
                                    {citiesList.map(({name,value},index)=>(
                                        <option value={value} key={index+1}>{name}</option>
                                    ))}
                                </select>
                                {city!=='' && <City cityid={city} aqhi_id={aqhi_id} />}
                            </Col>
                            <Col sm={{ span: 7, order:"first" }} className="social-col">
                                <h5>Share <br className="d-none d-sm-block"/>this lung</h5>
                                <FiFacebook onClick={this.fbShare} onKeyPress={this.checkKeyPressFB} tabIndex="0" />
                                {/* <FiInstagram /> */}
                                <FiTwitter onClick={this.twShare} onKeyPress={this.checkKeyPressTW} tabIndex="0"  />
                            </Col>
                        </Tween>
                    </Row>
                </Container>
            </Fragment>
          );
    }
}

export default DataViz;