import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class LungsInTheAir extends Component {
    componentDidMount() {}

    render() {
        return (
            <Container className="fullpage">
                <Row id="LungsInTheAir">
                    <Col>
                        <h1>Lungs</h1>
                        <h2>In the air</h2>
                        <p>Air pollution is a major public health concern</p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default LungsInTheAir;
