import React, { Component } from 'react';

import logo from '../img/breathe-logo.png';

import { Tween } from 'react-gsap';

class Footer extends Component {
    componentDidMount() {}

    render() {
        return (
            <footer id="Footer">
                <Tween
                    from={{
                        y: '50px',
                        opacity: 0,
                        scrollTrigger: {
                            trigger: '#Home',
                            start: '25% top',
                            toggleActions: 'play none none reverse',
                        },
                    }}
                >
                    <img
                        src={logo}
                        className="footer-logo"
                        alt="Breathe: The Lung Association"
                    />
                    <div>
                        <div>©2021 Canadian Lung Association. All Rights Reserved.</div>
                        <div><a href="https://www.lung.ca/privacy-policy-and-editorial-policy" target="_blank" rel="noreferrer">Privacy Statement</a>|<a href="https://www.lung.ca/disclaimer" target="_blank" rel="noreferrer">Disclaimer</a></div>
                    </div>
                    <a href="https://www.lung.ca/get-involved/donate/make-general-donation/online-donate-form?utm_source=CTA+on+website&utm_medium=microsite&utm_campaign=Lungs+in+the+Air&utm_id=Lungs+in+the+Air" target="_blank" rel="noreferrer">DONATE NOW</a>
                </Tween>
            </footer>
        );
    }
}

export default Footer;
