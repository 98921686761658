import React, { Component, Fragment } from 'react';
import { Navbar } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import NavList from './NavList';
import logo from '../img/lungs-logo.svg'

import ReactHowler from 'react-howler'

import { Tween } from 'react-gsap';

import eventBus from "./utils/EventBus";

class Header extends Component{

    constructor(props){
        super(props);
        this.state = {
            audioIsPlaying: false
        }
        this.handleAudioToggle = this.handleAudioToggle.bind(this);
        this.player = React.createRef();
    }

    componentDidMount(){
        eventBus.on("toggleAudio", () =>
            this.handleAudioToggle()
        );
    }

    handleAudioToggle(){
        const audioIsPlaying = !this.state.audioIsPlaying;

        this.setState({audioIsPlaying:audioIsPlaying});
    }
    handleLanguageToggle(){
        window.location.href = '/fr';
    }

    render(){
        const { audioIsPlaying } = this.state;
        return(
            <Fragment>   

                <Navbar fixed="top" expand="xs" collapseOnSelect variant="dark">
                    <Tween from={{
                                x: '-200px',
                                opacity: 0,
                                scrollTrigger: {
                                    trigger: '#Home',
                                    start: '25% top',
                                    toggleActions: 'play none none reverse'
                                }
                            }}>
                                <Navbar.Brand href="#">
                            <img src={logo} className="top-logo" alt="Lungs in the Air" />
                        </Navbar.Brand>
                    </Tween>

                    {/* <Tween from={{
                                marginLeft: 0,
                                scrollTrigger: {
                                    trigger: '#Home',
                                    start: '25% top',
                                    toggleActions: 'play none none reverse'
                                }
                            }}> */}
                        <div className="header-ui">
                            <Tween from={{
                                    display: 'none',
                                    opacity: 0,
                                    scrollTrigger: {
                                        trigger: '#Home',
                                        start: '25% top',
                                        toggleActions: 'play none none reverse'
                                    }
                                }}>
                                <div className="audio-toggle">Audio
                                    <button onClick={this.handleAudioToggle}>{audioIsPlaying?"on":"off"}</button>
                                </div>
                            </Tween>
                            <div className="language-toggle">
                                <button disabled>en</button>
                                <button onClick={this.handleLanguageToggle}>fr</button>
                            </div>
                        </div>
                    {/* </Tween> */}
                    
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />

                    <Navbar.Collapse id="basic-navbar-nav" dimension="width">
                        <NavList context="navbar" />
                    </Navbar.Collapse>
                </Navbar>
                
                <ReactHowler
                    src='audio/LungAssociation_UMBER_MUSIC_SFX_64.mp3'
                    playing={audioIsPlaying}
                    loop={true}
                    ref={ref => (this.player = ref)}
                />
                
            </Fragment>
          );
    }
}

export default withRouter(Header);