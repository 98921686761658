import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Image from './Image';

export default function StandardTabLayout({
    children,
    imageSrc,
    imageColor,
    imageAlt,
}) {
    return (
        <Row>
            <Col xs={{ order: 2 }} md={{ order: 1 }}>
                {children}
            </Col>
            <Col
                className="mb-4"
                xs={{ span: 8, offset: 2, order: 1 }}
                md={{ span: 4, offset: 1, order: 2 }}
            >
                <Image src={imageSrc} borderColor={imageColor} alt={imageAlt} />
            </Col>
        </Row>
    );
}
