import React from 'react';
import { FiChevronRight } from 'react-icons/fi';

import '../css/ButtonLink.scss';

export default function ButtonLink({ href, children }) {
    return (
        <a href={href} className="ButtonLink" target="_blank" rel="noreferrer">
            <span>{children}</span>
            <FiChevronRight />
        </a>
    );
}
