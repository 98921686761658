import React from 'react';
import ButtonLink from '../component/ButtonLink';
import ContentPage from '../component/ContentPage';
import Image from '../component/Image';
import StandardTabLayout from '../component/StandardTabLayout';
import { Tab, Tabs } from '../component/Tabs';
import { Col, Row } from 'react-bootstrap';
import ourLungs from '../img/lungs_img_0010_Our_Lungs_And_Air_Quality.png';
import healthInformation from '../img/lungs_img_0006_Free_Health_Information.png';
import research from '../img/lungs_img_0005_Research.png';
import talkToSomeone from '../img/lungs_img_0004_Talk_To_Someone.png';
import airQuality from '../img/lungs_img_0008_Air_Quality.png';
import vaping from '../img/lungs_img_0007_Vaping.png';
import smoking from '../img/lungs_img_0009_Smoking.png';

import { COLORS } from '../constants';

const IMAGES = {
    airQuality: {
        imageSrc: ourLungs,
        imageColor: COLORS.pink,
        imageAlt: 'Car and factory smoke',
    },
    healthInformation: {
        imageSrc: healthInformation,
        imageColor: COLORS.yellow,
        imageAlt: 'Person riding bicycle',
    },
    research: {
        imageSrc: research,
        imageColor: COLORS.aqua,
        imageAlt: 'City smoke stack',
    },
    talkToSomeone: {
        imageSrc: talkToSomeone,
        imageColor: COLORS.green,
        imageAlt: 'Lungs in the Air logo',
    },
};

function YourLungs() {
    return (
        <ContentPage id="YourLungs" title="Your Lungs">
            <Tabs>
                <Tab title="Our lungs and air quality">
                    <StandardTabLayout {...IMAGES.airQuality}>
                        <p>
                            Climate change and poor air quality are becoming a
                            global health crisis. Rising temperatures increase
                            the risks of air pollution and make our air quality
                            worse. While air pollutants in Canada have decreased
                            over the last few years, more needs to be done.
                        </p>
                        <p>
                            Canadians are already seeing how air pollution can
                            affect their health. In fact, more than 15,000
                            Canadians die due to air pollution-related causes
                            every year. Even more Canadians live with the
                            effects of air pollution every day.
                        </p>
                        <p>
                            Exposure to air pollution can lead to coughing and
                            wheezing, shortness of breath, getting infections
                            more easily, lung tissue redness and swelling and
                            other diseases. It can also worsen existing lung
                            diseases like asthma or COPD (chronic obstructive
                            pulmonary disease).
                        </p>
                        <p className="mt-4">
                            <ButtonLink href="https://www.lung.ca/?utm_source=CTA+on+website&utm_medium=microsite&utm_campaign=Lungs+in+the+Air&utm_id=Lungs+in+the+Air">
                                Learn More
                            </ButtonLink>
                        </p>
                    </StandardTabLayout>
                </Tab>
                <Tab title="Free health information">
                    <StandardTabLayout {...IMAGES.healthInformation}>
                        <p>
                            The Canadian Lung Association provides Canadians
                            like yourself with free health information resources
                            on a number of topics. From educational brochures,
                            videos or access to a respiratory educator, we are
                            here to provide you with information, support and
                            reassurance that you are not in this alone. We can
                            also provide you with tools to manage an existing
                            disease; resources to help you quit smoking or to
                            learn about other preventative measures you can take
                            to keep your lungs healthy.
                        </p>
                        <p className="mt-4">
                            <ButtonLink href="https://www.lung.ca/lung-health?utm_source=CTA+on+website&utm_medium=microsite&utm_campaign=Lungs+in+the+Air&utm_id=Lungs+in+the+Air">
                                Learn More
                            </ButtonLink>
                        </p>
                    </StandardTabLayout>
                </Tab>
                <Tab title="Research">
                    <StandardTabLayout {...IMAGES.research}>
                        <p>
                            The Canadian Lung Association has invested more than
                            $1.1 million into lung health research last year.
                            This money goes towards funding research that helps
                            us find new treatments, manage existing diseases and
                            provide support for young investigators. Every
                            dollar invested into lung health research means
                            someone can breathe a bit easier. The importance of
                            lung health research has become ever more evident
                            during the last two years. We need to fund Canada’s
                            brightest minds to ensure a breathable future.
                        </p>
                        <p className="mt-4">
                            <ButtonLink href="https://www.lung.ca/lung-research?utm_source=CTA+on+website&utm_medium=microsite&utm_campaign=Lungs+in+the+Air&utm_id=Lungs+in+the+Air">
                                Learn More
                            </ButtonLink>
                        </p>
                    </StandardTabLayout>
                </Tab>
                <Tab title="Talk to Someone">
                    <StandardTabLayout {...IMAGES.talkToSomeone}>
                        <p>
                            Have any questions and want to talk to an expert?
                            Call our toll-free helpline and speak to a
                            respiratory therapist 1-866-717-2673.
                        </p>
                    </StandardTabLayout>
                </Tab>
                <Tab title="Our mission">
                    <Row>
                        <Col xs={{ order: 2 }} md={{ span: 3, order: 1 }}>
                            <p>
                                The Canadian Lung Association continues to
                                advocate on behalf of all Canadians to ensure
                                that we live in a place that is easy to breathe
                                in for all. Our chief advocacy concerns are air
                                quality, vaping and tobacco control and a call
                                for more funding towards life-saving lung
                                research.
                            </p>
                            <p className="mt-4">
                                <ButtonLink href="https://www.lung.ca/?utm_source=CTA+on+website&utm_medium=microsite&utm_campaign=Lungs+in+the+Air&utm_id=Lungs+in+the+Air">
                                    Learn More
                                </ButtonLink>
                            </p>
                        </Col>

                        <Col
                            xs={{ order: 1, span: 12 }}
                            md={{ offset: 1, span: 8, order: 2 }}
                        >
                            <Row className="mb-4">
                                <Col
                                    xs={{ span: 8, offset: 2 }}
                                    md={{ span: 3, offset: 0 }}
                                    className="mb-4"
                                >
                                    <Image
                                        src={airQuality}
                                        borderColor="rgb(230,228,87)"
                                        alt="Car exhaust"
                                    />
                                </Col>
                                <Col md={9}>
                                    <h5>Air Quality</h5>
                                    <p>
                                        Investments are needed to help us
                                        achieve a net zero-emissions future and
                                        deal with the impacts of climate change.
                                        We are advocating for more investments
                                        towards sustainable transportation
                                        options; improved energy efficiency and
                                        addressing wood smoke.
                                    </p>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col
                                    xs={{ span: 8, offset: 2 }}
                                    md={{ span: 3, offset: 0 }}
                                    className="mb-4"
                                >
                                    <Image
                                        src={vaping}
                                        borderColor="rgb(81,135,115)"
                                        alt="Wispy smoke"
                                    />
                                </Col>
                                <Col md={9}>
                                    <h5>Vaping</h5>
                                    <p>
                                        Despite some success in restricting
                                        flavours and marketing tools that draw
                                        in young users, more work remains to be
                                        done. We support Health Canada’s recent
                                        announcement to ban flavours but urge
                                        that mint/menthol— the second most
                                        popular flavour amongst youth— are
                                        included within that ban.
                                    </p>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col
                                    xs={{ span: 8, offset: 2 }}
                                    md={{ span: 3, offset: 0 }}
                                    className="mb-4"
                                >
                                    <Image
                                        src={smoking}
                                        borderColor="rgb(231,138,138)"
                                        alt="Wispy smoke"
                                    />
                                </Col>
                                <Col md={9}>
                                    <h5>Smoking & tobacco control</h5>
                                    <p>
                                        Tobacco continues to be the leading
                                        cause of preventable disease and death
                                        in Canada. We are urging the government
                                        to make the tobacco industry pay for the
                                        costs of programming to prevent its use
                                        and help people to quit.
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Tab>
            </Tabs>
        </ContentPage>
    );
}

export default YourLungs;
