import * as THREE from "three";
// import { TweenLite } from 'gsap/TweenMax';

// import { useRef, useEffect } from "react";

import InteractiveControls from './controls/InteractiveControls';
import Particles from './particles/Particles';

// const glslify = require('glslify');

export default class WebGLView {

	constructor(app) {
		this.app = app;

		this.baselungs = 'images/lungs_centered.png';
		this.breathmap = 'images/breath-map.png';
		this.groupmap = 'images/group-map.png';

		this.sectionsettings = {
			home: {
				random: 5.0,
				offsetX: 0.0,
				offsetY: 0.0,
				scalar: 2.0,
				depth: 10.0
			},
			see: {
				random: 5.0,
				offsetX: 0.0,
				offsetY: 0.0,
				scalar: .8,
				depth: 5.0
			},
			lungs: {
				random: 5.0,
				offsetX: -50.0,
				offsetY: 0.0,
				scalar: 1.0,
				depth: 5.0,
				mobile: {
					offsetX: 0.0,
					offsetY: 50.0,
					scalar: .6
				}
			},
			YourLungs: {
				random: 10.0,
				offsetX: 55.0,
				offsetY: -60.0,
				scalar: 2.0,
				depth: 15.0
			},
			About: {
				random: 10.0,
				offsetX: -63.0,
				offsetY: 58.0,
				scalar: 3.0,
				depth: 15.0
			},
			Social: {
				random: 10.0,
				offsetX: 0.0,
				offsetY: 0.0,
				scalar: 2.0,
				depth: 20.0
			}
		}

		this.initThree();
		this.initParticles();
		this.initControls();

		this.showLungs();
	}

	initThree() {
		// scene
		this.scene = new THREE.Scene();

		// camera
		this.camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 1, 10000);
		this.camera.position.z = 300;

		// renderer
        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });

        // clock
		this.clock = new THREE.Clock(true);
	}

	initControls() {
		this.interactive = new InteractiveControls(this.camera, this.renderer.domElement);
	}

	initParticles() {
		this.particles = new Particles(this);
		this.particles.loadSupportTextures( this.breathmap, this.groupmap, this.baselungs );
		this.scene.add(this.particles.container);
	}

	// ---------------------------------------------------------------------------------------------
	// PUBLIC
	// ---------------------------------------------------------------------------------------------

	update() {
		const delta = this.clock.getDelta();

		if (this.particles) this.particles.update(delta);
	}

	draw() {
		this.renderer.render(this.scene, this.camera);
	}

	showLungs(){
		// init next
		if (this.currSample == null) this.particles.init();
		// hide curr then init next
		else {
			this.particles.hide(true).then(() => {
				this.particles.init(this.baselungs);
			});
		}
		this.currSample=-1;
	}

	toggle(which){
		this.particles.animate("uRandom",this.sectionsettings[which].random,1.5);
		this.particles.animate("uDepth",this.sectionsettings[which].depth,2.0);
		if (this.sectionsettings[which].mobile && window.innerWidth<576){
			this.particles.animate("uOffset",[this.sectionsettings[which].mobile.offsetX,this.sectionsettings[which].mobile.offsetY]);
			this.particles.animate("uScalar",this.sectionsettings[which].mobile.scalar);
		} else {
			this.particles.animate("uOffset",[this.sectionsettings[which].offsetX,this.sectionsettings[which].offsetY]);
			this.particles.animate("uScalar",this.sectionsettings[which].scalar);
		}


	}

	updateData(arr){
		this.particles.animate("uParticles",arr);
		// this.particles.setData("uParticles",arr);
	}

	// ---------------------------------------------------------------------------------------------
	// EVENT HANDLERS
	// ---------------------------------------------------------------------------------------------

	resize() {
		if (!this.renderer) return;
		this.camera.aspect = window.innerWidth / window.innerHeight;
		this.camera.updateProjectionMatrix();

		this.fovHeight = 2 * Math.tan((this.camera.fov * Math.PI) / 180 / 2) * this.camera.position.z;

		this.renderer.setSize(window.innerWidth, window.innerHeight);

		if (this.interactive) this.interactive.resize();
		if (this.particles) this.particles.resize();
	}
}
