// import ReactDOM from 'react-dom'
import React, { Component } from 'react'
// import * as THREE from "three";
import WebGLView from './webgl/WebGLView';

import eventBus from "./utils/EventBus";

class Lungs extends Component{
    constructor(props){
      super(props);
      this.player = React.createRef();
    }

    componentDidMount(){

      window.addEventListener('resize', this.updateDimensions);

      eventBus.on("updateBackground", (data) =>
        this.updateBackground(data)
      );
      eventBus.on("updateLungParticles", (data) =>
        this.updateParticleData(data)
      );
      this.tick = this.tick.bind(this); // allow tick to use 'this' scope
    }

    componentWillUnmount(){
      eventBus.remove("updateBackground");
      eventBus.remove("updateLungParticles");
      window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
      this.debounce( function(){ this.webgl.resize() }, 100 );
    }

    // event listeners to pass data into webgl.
    updateBackground(data){
      if (!this.animating){
        this.animate();
      // } else if (data.message==="lungs"){
        // this.webgl.showLungs();
        // this.webgl.toggle('lungs');
      } else {
        // this.webgl.next();
        this.webgl.toggle(data.message);
      }
    }
    updateParticleData(data){
      const map = data.message.map((el)=> {
        return isNaN(el.aqi) ? 0.0 : el.aqi;
      });
      this.webgl.updateData(map);
    }

    // webgl animation functions
    animate(){
      console.log('start animation');
      this.animating = true;

      this.webgl = new WebGLView(this);
      this.mount.appendChild( this.webgl.renderer.domElement );
      this.webgl.resize(); // todo. should resize on window resize too.

      requestAnimationFrame(this.tick);
    }
    tick(){
      this.webgl.update();
      this.webgl.draw();
      requestAnimationFrame(this.tick);
    }
    debounce(func,timeout){
      // console.log('debounce',this.timer);
      // return (...args) => {
        if (this.timer===undefined) {
          // console.log('apply func!');
          func.apply(this);
        }
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.timer = undefined;
        }, timeout);
      // };
    }

    render(){
        return(
          <div ref={ref => (this.mount = ref)} className="viz" />
        );
    }
}

export default Lungs;
