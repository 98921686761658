import React, { Component, useContext } from 'react';
import { Accordion, Card, AccordionContext, Modal } from 'react-bootstrap';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

import eventBus from "../component/utils/EventBus";
import { Tween } from 'react-gsap';
import axios from 'axios'; 

const particleInfo = [
    { name: 'Fine Particles', sym:'PM2.5', details: 'Fine particulate matter is a mixture of tiny (about the width of a human hair) particles of liquids and solids that pollute the air. These can be inhaled deep into the lungs. They can either be emitted directly by vehicles, various industries, wildfires or wood burning or by construction, among others.', id:'p2' },
    { name: 'Ozone', sym:'O3', details: 'Ground-level ozone forms when nitrogen oxide and volatile organic compounds (VOCs) from vehicle exhaust, factory emissions and other sources react in sunlight. It can be a major component of smog during the summer, especially during hot, sunny weather. Ozone can be transported long distances and can be responsible for large regional air pollution episodes.', id:'o3' },
    { name: 'Nitrogen Dioxide', sym:'NO2', details: 'Nitrogen dioxide is formed by burning fossil fuels in motor vehicles, homes and in various industries. Along with the ozone pollutant, it’s one of the chemicals found in smog.', id:'n2' },
    { name: 'Sulfur Dioxide', sym:'SO2', details: 'Sulfur Dioxide is a colourless gas that smells like burnt matches. It is one of the main ingredients in acid rain. Sources of SO2 include smelters, paper and steel mills, fossil fuels burnt in petroleum refineries and diesel vehicles among others. Exposure to high levels of SO2 can cause breathing problems,  a new lung disease and worsening of existing diseases, like asthma or COPD.', id:'s2' },
    { name: 'Carbon Monoxide', sym:'CO', details: 'Carbon Monoxide is an odourless, tasteless, colourless and poisonous gas. Carbon Monoxide is produced primarily by burning of fossil fuels, with its main source being the transportation sector. This gas can enter the blood stream and reduce oxygen delivery to your organs and tissues.', id:'co' },
];

const aqiInfo = [
    { risk: 'LOW<br/>HEALTH<br/>RISK', desc: 'Air Quality Health Index is # and is considered a low health risk. Enjoy your usual outdoor activities.' },
    { risk: 'MODERATE<br/>HEALTH<br/>RISK', desc: 'Air Quality Health Index is # and is considered a moderate health risk. No need to modify plans unless you experience symptoms.' },
    { risk: 'HIGH<br/>HEALTH<br/>RISK', desc: 'Air Quality Health Index is # and is considered a high health risk. Reduce or reschedule your outdoor plans.' },
    { risk: 'VERY HIGH<br/>HEALTH<br/>RISK', desc: 'Air Quality Health Index is # and is considered a very high health risk. Avoid the outdoors if you can.' },
    { risk: '', desc: 'Air Quality Health Index data is not available for this city.' }
]

//https://dd.weather.gc.ca/air_quality/aqhi/pnr/observation/realtime/xml/AQ_OBS_IAKID_CURRENT.xml

function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
      <button
        className={ isCurrentEventKey ? 'active' : '' }
        onClick={decoratedOnClick}          
      >
        {children}
      </button>
    );
}

class ParticleTable extends Component{

    componentDidMount(){
        this.handleShowModal = this.handleShowModal.bind(this); 
        this.handleCloseModal = this.handleCloseModal.bind(this); 
        this.fetchAQHIData(this.props.cityid, this.props.aqhi_id);
    }

    componentDidUpdate(prevProps){
        // console.log('componentDidUpdate',prevProps,this.props);
        if (prevProps.cityid!==this.props.cityid){
            this.fetchAQHIData(this.props.cityid, this.props.aqhi_id);
        }
    }

    state = {
        particleDataLoaded: 0,
        thisCityId:null,
        thisCityAQI:null,
        thisCityAQILevel:null,
        particleArray:[],
        showModal:false
    }

    fetchAQHIData(cityid,aqhi_id){
        // console.log('fetchAQHIData');
        this.setState({particleDataLoaded:0});

        const aqhiquery = 'https://lungs-proxy-prod-cjk6tnmbhq-uc.a.run.app/aqhi/'+aqhi_id+'?key=AxnC4MepY2vjtTwy9E9tvcCxLqAFHz';
        axios.get(aqhiquery)
            .then(res => {
                // console.log(res);
                const aqi = Number(res.data.data.aqhi);
                if (isNaN(aqi)){
                    this.setState({particleDataLoaded:1,
                        thisCityId:cityid,
                        thisCityAQI:"N/A",
                        thisCityAQILevel:4});
                    this.fetchParticleData(cityid);
                    return;
                }
                let aqilevel = 3;
                if (aqi<=3){ 
                    aqilevel=0; 
                } else if (aqi<=6) { 
                    aqilevel=1;
                } else if (aqi<=10) {
                    aqilevel=2;
                }
                this.setState({particleDataLoaded:1,
                    thisCityId:cityid,
                    thisCityAQI:aqi,
                    thisCityAQILevel:aqilevel});
                this.fetchParticleData(cityid);
            })
            .catch(error => {
                this.setState({particleDataLoaded:1,
                    thisCityId:cityid,
                    thisCityAQI:"N/A",
                    thisCityAQILevel:4});
                this.fetchParticleData(cityid);
            });
    }
    fetchParticleData(cityid){
        const city = cityid.split(',')[0];
        const state = cityid.split(',')[1];
        const country = 'Canada';
        const apikey = '5fdd2570-4849-481e-b267-549816eb949b';

        const query = 'https://api.airvisual.com/v2/city?city=' + city +
                      '&state=' + state +
                      '&country=' + country +
                      '&key=' + apikey;

        
        axios.get(query)
        .then(res => {
            if (res.data && res.data.status==='success') {
                const data = res.data.data;
                // const pollution = data.current.pollution;
                const history = data.history.pollution;
                let newdata = [];
                particleInfo.forEach( el => {
                    const id = el.id;
                    let cval, caqi, hval, delta;
                    // if current value doesn't exist, loop through last 12 hrs of data to get last value.
                    for (var i=0;i<12;i++){
                        if (history[i][id]){
                            if (cval===undefined) { // current value has not been set yet. use it.
                                cval = history[i][id].conc;
                                caqi = history[i][id].aqius/50; // us aqi value.
                            } else if (hval===undefined) { // current value set, but not historical value. use it.
                                hval = history[i][id].conc;
                                break;
                            }
                        }
                    }
                    if (cval!==undefined && hval!==undefined){ // we have a current and a historical value to compare.
                        if (cval>hval) delta = 'gt';
                        if (cval<hval) delta = 'lt';
                    }

                    if (cval===undefined){
                        cval = 'n/a';
                    }
                    newdata.push( {val:cval,delta:delta,aqi:caqi} );
                });
                eventBus.dispatch("updateLungParticles", { message: newdata });
                this.setState({particleDataLoaded:2,
                    particleArray:newdata
                });
            } else {
                // todo. error?
            }
        })
    }

    handleShowModal(){
        this.setState({showModal:true})
    }
    handleCloseModal(){
        this.setState({showModal:false})
    }
    
    checkKeyPress = (e) => {
        console.log('keypress',e);
        if (e.code==='Enter' || e.code==='Space'){
            this.handleShowModal();
            return false;
        }
    }

    render(){
        // const { cityid } = this.props;
        const { particleDataLoaded, thisCityAQI, thisCityAQILevel, particleArray, showModal } = this.state;
        let aqiRisk, aqiCopy;
        if (particleDataLoaded>0){
            aqiRisk = aqiInfo[thisCityAQILevel].risk;
            aqiCopy = aqiInfo[thisCityAQILevel].desc.replace('#',thisCityAQI);
        }
        
        
        // console.log(this.props.cityid, data, citydata);
        
        return(
            <div className="particleTable">
                {particleDataLoaded>0 && <div className="aqhi-head mb-3">
                    <h5>AIR QUALITY<sup tabIndex="0" onClick={this.handleShowModal} onKeyPress={this.checkKeyPress}>?</sup><br/>HEALTH INDEX</h5>
                    <div><span>{thisCityAQI}</span><span dangerouslySetInnerHTML={{__html:aqiRisk}}/></div>
                </div>}
                {particleDataLoaded>0 && <p>{aqiCopy}</p>}
                {particleDataLoaded>1 && <Accordion>
                    <Tween from={{
                        x:100,
                        opacity:0, 
                        stagger: .1
                    }}>
                    {particleInfo.map(({ name, sym, details, id },index) => (
                        <Card className={'p-'+id} key={sym}>
                            <Card.Header>
                                <ContextAwareToggle eventKey={sym}>
                                    <em>{sym}</em> {name} <span className={particleArray[index].delta} data-aqi={particleArray[index].aqi}>{particleArray[index].val}</span>
                                </ContextAwareToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={sym}>
                                <Card.Body>{details}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                    </Tween>
                </Accordion>}
                <Modal show={showModal} onHide={this.handleCloseModal} centered>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <p>The Air Quality Health Index (or AQHI) is a scale measured from 1 – 10+ that helps you understand what the air quality around you means to your health. There are three major air pollutants that influence the AQHI: ozone, fine particulate matter and nitrogen dioxide. As a tool, you can protect your lungs by avoiding exposure when air pollutants are high. If you suffer from lung disease you can use the AQHI to plan your activities around the times when air quality is better.</p>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ParticleTable;