import React from 'react';
import '../css/Tabs.scss';
import ReactSwipe from 'react-swipe';

export function Tabs({ children }) {
    const [activeTabIndex, setActiveTabIndex] = React.useState(0);
    const reactSwipeEl = React.useRef(null);

    return (
        <>
            <div className="TabsContainer">
                {React.Children.map(children, ({ props }, index) => (
                    <button
                        className={
                            'Tab' + (index === activeTabIndex ? ' active' : '')
                        }
                        onClick={() => {
                            reactSwipeEl.current.slide(index);
                            setActiveTabIndex(index);
                            // console.log(reactSwipeEl.current);
                            // const el = reactSwipeEl.current.containerEl;
                            // const pos = reactSwipeEl.current.swipe.getPos();
                            // const children = reactSwipeEl.current.props.children;
                            //console.log(el,pos,children[pos]);
                        }}
                    >
                        {props.title}
                    </button>
                ))}
            </div>
            <div className="TabsContent">
                <ReactSwipe ref={reactSwipeEl} options={{ continuous: false }}>
                    {React.Children.map(children, ({ props }, index) => {
                        return <div key={index}>{props.children}</div>;
                    })}
                </ReactSwipe>
            </div>
        </>
    );
}

export function Tab({ children, title }) {
    return (
        <div>
            <h2>{title}</h2>
            <div>{children}</div>
        </div>
    );
}
