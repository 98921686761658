import React from 'react';
import ContentPage from '../component/ContentPage';
import { Tab, Tabs } from '../component/Tabs';
import StandardTabLayout from '../component/StandardTabLayout';

import lungs from '../img/lungs_img_0003_Lungs_in_The_Air.png';
import artist from '../img/lungs_img_0011_The_Artist.png';
import calgary from '../img/Calgary_.jpg';
import toronto from '../img/Toronto_.jpg';
import halifax from '../img/Nova-Scotia_.jpg';
import glow from '../img/glow.jpg';
import airliquide from '../img/logo-airliquide.png';
import rhs from '../img/logo-rhs.png';
import vitalaire from '../img/logo-vitalaire.png';
import { COLORS } from '../constants';
import ButtonLink from '../component/ButtonLink';
import { Col, Row } from 'react-bootstrap';
import Image from '../component/Image';

export default function About() {
    return (
        <ContentPage id="About" title="About">
            <Tabs>
                <Tab title="Lungs in the Air">
                    <StandardTabLayout
                        imageSrc={lungs}
                        imageColor={COLORS.aqua}
                        imageAlt="Lungs"
                    >
                        To show how the health of our lungs is up in the air we
                        developed the #LungsInTheAir: a 4-story floating art
                        sculpture that will travel across the country to help
                        spread the importance of lung health.
                    </StandardTabLayout>
                </Tab>
                <Tab title="The Journey">
                    <Row>
                        <Col
                            xs={{ span: 8, offset: 2 }}
                            md={{ span: 4, offset: 0 }}
                            className="mb-4 text-center"
                        >
                            <p className="mb-4">
                                <Image
                                    alt="Calgary"
                                    borderColor={COLORS.yellow}
                                    src={calgary}
                                />
                            </p>
                            <h5>Calgary</h5>
                            <p>
                                September 25, 2021
                                <br />
                                10:30AM-4:30PM local time
                                <br />
                                East Park Village
                            </p>
                        </Col>
                        <Col
                            xs={{ span: 8, offset: 2 }}
                            md={{ span: 4, offset: 0 }}
                            className="mb-4 text-center"
                        >
                            <p className="mb-4">
                                <Image
                                    alt="Toronto"
                                    borderColor={COLORS.pink}
                                    src={toronto}
                                />
                            </p>
                            <h5>Toronto</h5>
                            <p>
                                September 29, 2021
                                <br />
                                11AM-7PM local time
                                <br />
                                Roundhouse Park
                            </p>
                        </Col>
                        <Col
                            xs={{ span: 8, offset: 2 }}
                            md={{ span: 4, offset: 0 }}
                            className="mb-4 text-center"
                        >
                            <p className="mb-4">
                                <Image
                                    alt="Halifax"
                                    borderColor={COLORS.green}
                                    src={halifax}
                                />
                            </p>
                            <h5>Halifax</h5>
                            <p>
                                October 4, 2021
                                <br />
                                10:30AM-4:30PM local time
                                <br />
                                Waterfront
                            </p>
                        </Col>
                    </Row>
                </Tab>
                <Tab title="The Artist">
                    <StandardTabLayout
                        imageSrc={artist}
                        imageColor={COLORS.orange}
                        imageAlt="Fezz Stenton"
                    >
                        <p>
                            Fezz Stenton is an artist from Toronto who has
                            performed visuals for musicians such as Juan Atkins,
                            Four Tet and Nina Kraviz a Toronto's Electric Island
                            festival and has animated custom content for artists
                            like Grandtheft and Bad Bunny (Xite Labs).
                        </p>
                        <p>
                            He's always been interested in cutting-edge
                            technologies, breaking things, putting them back
                            together and discovering something new. Using tools
                            like Resolume Arena, Touch Designer, Unity 3D and
                            Cinema 4D, he continues to explore new techniques
                            with each project.
                        </p>
                        <p className="mt-4">
                            <ButtonLink href="https://fezzstenton.com">See his work</ButtonLink>
                        </p>
                    </StandardTabLayout>
                </Tab>
                <Tab title="The Manufacturer">
                    <StandardTabLayout
                        imageSrc={glow}
                        imageColor={COLORS.yellow}
                        imageAlt="Glow Inflatables"
                    >
                        <p>
                        Glow Inflatables Ltd are an international, premium supplier of custom inflatables; their expertise covers every stage of the process, including design, manufacture, supply, installation and aftercare. They have made customizable inflatables for some of the biggest events, brands and acts in the world, such as Cold Play, Formula 1, Fortnite and ITV. 
                        </p>
                        <p className="mt-4">
                            <ButtonLink href="https://www.glowinflatables.com">See their work</ButtonLink>
                        </p>
                    </StandardTabLayout>
                </Tab>
                <Tab title="Our Supporters">
                    <Row>
                        <Col 
                            xs={{ order: 4 }} 
                            md={{ span: 5, order: 1 }}>
                            <p>
                            With over 100 years of experience in the healthcare industry, including almost half a century in Canada, Air Liquide and its homecare respiratory subsidiaries, VitalAire and Respiratory Homecare Services are proud to support this year’s Canadian Lung Association campaign across the country.
                            </p>
                            <p className="mt-4">
                                <ButtonLink href="https://www.airliquide.com">Learn more about them</ButtonLink>
                            </p>
                        </Col>
                        <Col
                            className="mb-4"
                            xs={{ span: 4, order: 1 }}
                            md={{ span: 2, offset: 1, order: 2 }}
                        >
                        <Image src={vitalaire} borderColor={COLORS.orange} alt="Vitalaire" />
                        </Col>
                        <Col
                            className="mb-4"
                            xs={{ span: 4, order: 2 }}
                            md={{ span: 2, order: 3 }}
                        >
                        <Image src={airliquide} borderColor={COLORS.pink} alt="Air Liquide" />
                        </Col>
                        <Col
                            className="mb-4"
                            xs={{ span: 4, order: 3 }}
                            md={{ span: 2, order: 4 }}
                        >
                        <Image src={rhs} borderColor={COLORS.aqua} alt="Respiratory Homecare Solutions" />
                        </Col>
                    </Row>
                </Tab>
            </Tabs>
        </ContentPage>
    );
}
