// import logo from './logo.svg';
import './css/App.scss';
import { Header, Lungs, Footer } from './component/';
import { Home, See, DataViz, YourLungs, About, Social } from './page/';

import { BrowserRouter as Router } from 'react-router-dom';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function App() {
    return (
        <div className="App">
            <Lungs />
            <Router>
                <Header />
                <Home />
                <See />
                <DataViz />
                <YourLungs />
                <About />
                <Social />
            </Router>
            <Footer />
        </div>
    );
}

export default App;
