import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';

import { withRouter } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

const LINKS = [
    { to: '#', label: 'Home' },
    { to: '#YourLungs', label: 'Your Lungs' },
    { to: '#About', label: 'About' },
    { to: '#Social', label: 'Social' },
    { to: 'https://www.lung.ca/get-involved/donate/make-general-donation/online-donate-form?utm_source=CTA+on+website&utm_medium=microsite&utm_campaign=Lungs+in+the+Air&utm_id=Lungs+in+the+Air', label: 'Donate', external: true },
];

class NavList extends Component {
    componentDidMount() {}

    render() {
        // const path = this.props.location.pathname;

        return (
            <Nav>
                {LINKS.map(({ to, label, external }, index) => (
                    <li key={to}>
                        {external ? <Nav.Link
                            href={to}
                            eventKey={index.toString()}
                            target="_blank"
                        >
                            {label}
                        </Nav.Link>
                        :
                        <Nav.Link
                            as={NavHashLink}
                            smooth
                            to={to}
                            eventKey={index.toString()}
                            activeClassName="active"
                        >
                            {label}
                        </Nav.Link>}
                    </li>
                ))}
            </Nav>
        );
    }
}

export default withRouter(NavList);
