import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import eventBus from "../component/utils/EventBus";
import { Tween } from 'react-gsap';

class Donate extends Component{

    componentDidMount(){
        
    }

    dispatchBG(){
        eventBus.dispatch("updateBackground", { message: "donate" });
    }

    render(){
        return(
            <Fragment>
                <Container className="fullpage" id="Donate">
                    <Row>
                        <Col>
                            <Tween from={{
                                x: '-200px',
                                opacity: 0,
                                scrollTrigger: {
                                    trigger: '#Donate',
                                    scrub: true,
                                    start: 'top bottom',
                                    end: 'top center'
                                }
                            }} onComplete={this.dispatchBG}>
                                <h2>Donate</h2>
                            </Tween>
                            <h2>In the air</h2>
                            <p>Air pollution is a major public health concern</p>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
          );
    }
}

export default Donate;