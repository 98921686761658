import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../css/ContentPage.scss';
import { Tween } from 'react-gsap';
import eventBus from './utils/EventBus';

function ContentPage({ id, title, children }) {
    const dispatchBG = function () {
        eventBus.dispatch('updateBackground', { message: id });
    };

    return (
        <Container className="ContentPageContainer" id={id}>
            <Row>
                <Col>
                    <Tween
                        from={{
                            x: '-200px',
                            opacity: 0,
                            scrollTrigger: {
                                trigger: `#${id}`,
                                start: 'top 75%',
                                toggleActions: 'play reverse play reverse',
                            },
                        }}
                        onComplete={dispatchBG}
                    >
                        <h1 className="ContentPageTitle">{title}</h1>
                    </Tween>
                    {children}
                </Col>
            </Row>
        </Container>
    );
}

function Section({ title, children }) {
    return (
        <div className="ContentPageSection">
            <h2 className="ContentPageSectionTitle">{title}</h2>
            {children}
        </div>
    );
}

ContentPage.Section = Section;

export default ContentPage;
